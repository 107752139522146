<script setup>
// imports
import {
  computed,
  defineAsyncComponent,
  inject,
  onMounted,
  provide,
  ref,
  watch,
  watchEffect,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useForm } from 'vee-validate';
import { until, useBroadcastChannel } from '@vueuse/core';
import { useRouteParams } from '@vueuse/router';
import useTimeRemaining from '@/composables/useTimeRemaining';
import useApplicantInfo from '@/composables/useApplicantInfo';
import useCodility from '@/composables/useCodility';
import api from '@/api';

// components
import { PDialog } from '@/components/ProxifyUI';
const ApplicantActivityInterviewScorecardHeader = defineAsyncComponent(
  () =>
    import(
      '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardHeader.vue'
    )
);
const ApplicantActivityInterviewScorecardFooter = defineAsyncComponent(
  () =>
    import(
      '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardFooter.vue'
    )
);
const ApplicantActivityInterviewScorecardBody = defineAsyncComponent(
  () =>
    import(
      '@/components/Elements/Applicant/ApplicantActivityInterviewScorecardBody.vue'
    )
);
const RescheduleCard = defineAsyncComponent(
  () =>
    import(
      '@/components/Elements/Applicant/ApplicantActivityInterviewRescheduleCard.vue'
    )
);

// props
const props = defineProps({
  applicationData: {
    type: Object,
    default: () => ({}),
  },
  codilityScore: {
    type: Number,
    required: false,
    default: undefined,
  },
  interview: {
    type: Object,
    default: () => ({}),
  },
});

// emits
const emit = defineEmits(['open-reschedule-dialog', 'close']);

// template refs
const scorecardBody = ref(null);

// state
const store = useStore();
const route = useRoute();
const schema = ref({});
const answers = ref({});
const applicant = ref(props.applicationData);
const interview = ref(props.interview);
const averageCodilityScore = ref(props.codilityScore);
const requiredFields = ref([]);
const requiredScorecardQuestions = ref([]);
const rescheduleReason = ref('');

// compositions
const {
  codilityScore: codilityScoreValue,
  codilityTests: codilityTestsValue,
  getApplicantTests,
  getTests,
} = useCodility();
const interviewId = useRouteParams('interviewId');
const applicationId = useRouteParams('id');
const { timeRemaining } = useTimeRemaining(
  computed(() => interview.value?.calendarEvent)
);
const { applicant: applicantInfo, getApplicantInfo } = useApplicantInfo();
const { post, isClosed } = useBroadcastChannel({ name: 'data-needs-update' });

// vuex states
const isLoading = computed(() => store.getters['appTraffic/isLoading']);
const interviewers = computed(() => store.state.application.interviewers);
const recruiters = computed(() => store.state.applicant.recruiters);
const currentUser = computed(() => store.state.auth.user);
const skills = computed(() => store.state.applicant.skills);

// computed values
const isFullstack = computed(() =>
  (applicant.value.competency ?? '').includes('Fullstack')
);
const hasEditAccess = computed(
  () => interview.value?.interviewer?.id === currentUser.value?.id
);
const postDecisionActions = computed(() => {
  return scorecardBody.value?.postDecisionActions ?? {};
});
const published = computed(() => interview.value?.published_at);
const conditionalQuestions = computed(() => {
  return (schema.value?.questions ?? []).filter(({ condition }) => condition);
});
const notesQuestion = computed(() =>
  (schema.value?.questions ?? []).find(
    ({ group }) => group.toLowerCase() === 'notes'
  )
);
const ratingQuestion = computed(() =>
  (schema.value?.questions ?? []).find(({ type }) => type === 'rating')
);
const validationSchema = computed(() => {
  return requiredFields.value.reduce((accumulator, name) => {
    accumulator[name] = { required: true };
    return accumulator;
  }, {});
});

const isRateGuidanceEnabled = computed(() => {
  return applicant.value?.compensation_plan === 'monthly';
});

// form validations
const { values, validate } = useForm({ validationSchema: validationSchema });

const getMainSkillIds = (applicationSkills) => {
  return applicationSkills
    ?.filter(({ main_skill: isMain }) => isMain)
    .map(({ skill }) => skill.id);
};

const findSkillByCompetency = (mainSkills, competency) => {
  return mainSkills?.find(({ skill: { skill_stack_names: competencies } }) => {
    return competencies.includes(competency);
  });
};

const getSkillByCompetency = (competency) => {
  const applicationSkills = applicant.value?.application_skills;
  const mainSkillIds = getMainSkillIds(applicationSkills);
  const mainSkills = skills.value?.filter(({ id }) =>
    mainSkillIds?.includes(id)
  );

  const applicationMainSkills = mainSkills?.map((skill) => {
    const appSkill = applicationSkills?.find(
      ({ skill: { id } }) => id === skill.id
    );
    return { ...(appSkill ?? []), skill };
  });

  return findSkillByCompetency(applicationMainSkills, competency);
};

const isValidSkill = (skill) =>
  Boolean(skill && skill.skill && skill.years && skill.proficiency_level);

const skillCheckFunctions = {
  frontend_skill: () => isValidSkill(getSkillByCompetency('Frontend')),
  backend_skill: () => isValidSkill(getSkillByCompetency('Backend')),
  primary_skill: () => {
    const skills = applicant.value?.application_skills;
    const mainSkill = skills?.find(({ is_primary: isPrimary }) => isPrimary);
    return isValidSkill(mainSkill);
  },
};

const rateCheckFunctions = {
  hourly_rate: () =>
    Boolean(
      applicant.value?.rate?.hourly_rate_updated ??
        applicant.value?.rate?.hourly_rate
    ),
  monthly_rate: () =>
    Boolean(
      applicant.value?.rate?.monthly_rate_updated ??
        applicant.value?.rate?.monthly_rate
    ),
  gig_overtime_hourly_rate: () =>
    Boolean(
      applicant.value?.rate?.gig_overtime_hourly_rate_updated ??
        applicant.value?.rate?.gig_overtime_hourly_rate
    ),
};

const isValidInterviewField = () => {
  return Boolean(
    postDecisionActions.value.interviewer_id ||
      postDecisionActions.value.interview_group_id
  );
};

const getValidItems = (requiredFields, validationFunction) => {
  return requiredFields.filter((field) => {
    if (field === 'interviewer_id' || field === 'interview_group_id') {
      return isValidInterviewField();
    }
    return validationFunction(field);
  });
};

const formProgress = computed(() => {
  const requiredCrossCheckQuestions =
    getRequiredCrossCheckQuestions().properties;
  const requiredPostDecisionActionFields =
    getFinalDecisionActionFields().properties;
  const requiredQuestions = getRequiredScorecardQuestions().map(
    (name) => name.split('_')[0]
  );

  const validQuestions = getValidItems(requiredQuestions, (question) =>
    Array.isArray(answers.value[question])
      ? answers.value[question].length
      : answers.value[question]
  );
  const rateProperties = Object.keys(applicant.value?.rate ?? {});
  const validateCrossCheckQuestionsCallback = (key) => {
    if (rateProperties.includes(key))
      return (
        rateCheckFunctions[key]?.() ?? Boolean(applicant.value?.rate?.[key])
      );
    if (skillCheckFunctions[key]) return skillCheckFunctions[key]();
    return Boolean(applicant.value[key]);
  };
  const validCrossCheckQuestions = getValidItems(
    requiredCrossCheckQuestions,
    validateCrossCheckQuestionsCallback
  );
  const validPostDecisionActionFields = getValidItems(
    requiredPostDecisionActionFields,
    (key) =>
      key === 'reasons'
        ? Object.values(postDecisionActions.value.reasons).some(
            (reason) => reason.is_main
          )
        : postDecisionActions.value[key]
  );

  const totalValid =
    validQuestions.length +
    validCrossCheckQuestions.length +
    validPostDecisionActionFields.length;
  const totalRequired =
    requiredQuestions?.length +
    requiredCrossCheckQuestions.length +
    requiredPostDecisionActionFields.length;

  return forcePublish.value
    ? (validPostDecisionActionFields.length + validCrossCheckQuestions.length) /
        (requiredPostDecisionActionFields.length +
          requiredCrossCheckQuestions.length)
    : totalValid / totalRequired;
});

const triggerValidate = async () => {
  const { valid, errors } = await validate();
  if (!valid) {
    const firstInvalidField = document.querySelector(
      `[data-name="${Object.keys(errors)[0]}"]`
    );
    firstInvalidField?.scrollIntoView?.({ behavior: 'smooth' });
    showValidationAlert.value = true;
    setTimeout(() => {
      showValidationAlert.value = false;
    }, 2000);
  }
  return valid;
};

// flags
const isQuestionsLoading = ref(false);
const forcePublish = ref(
  interview.value?.final_decision_action?.cut_short ?? false
);
const readOnly = ref(Boolean(published.value));
const showSavedMessage = ref(false);
const showAutoSavedMessage = ref(false);
const showValidationAlert = ref(false);
const isSaving = ref(false);
const isManualSaving = ref(false);
const isRescheduleDialogOpen = ref(false);

// mounted hook
onMounted(async () => {
  await getApplicantInfo();
  await getApplicantTests();
  await getTests();
});

// API Calls

const getInterview = async () => {
  const { data: { data } = {} } =
    await api.applications.interviews.getInterview(interviewId.value);
  interview.value = data;
  forcePublish.value = data.final_decision_action?.cut_short ?? false;
};

const getInterviewers = async () => {
  const { data: recruiters } = await api.users.recruiters();
  store.commit('applicant/setRecruiters', recruiters.data);
  const { data: interviewers } = await api.users.techInterviewers();
  store.commit('application/setInterviewers', interviewers.data);
};

const getInterviewQuestions = async () => {
  isQuestionsLoading.value = true;
  const { data: { interview: interviewSchema, answers: userAnswers } = {} } =
    await api.applications.interviews.getInterviewQuestions(
      interview.value.id ?? interviewId.value
    );
  schema.value = interviewSchema;
  answers.value = {
    ...userAnswers.reduce((accumulator, answer) => {
      const answerModifier = {
        label_group: (answer) => parseAnswer(answer.answer),
        multi_select: (answer) => parseAnswer(answer.answer),
        checkbox: (answer) => Boolean(answer.answer),
        rating: (answer) => (answer.answer ? Number(answer.answer) : undefined),
      };
      accumulator[answer.interview_question_id] =
        answerModifier[
          schema.value.questions.find(
            ({ id }) => id === answer.interview_question_id
          )?.type
        ]?.(answer) ?? answer.answer;
      return accumulator;
    }, {}),
  };
  isQuestionsLoading.value = false;
};

const getSkills = () => {
  return api.data.skills().then(({ data: skills }) => {
    store.commit('applicant/setSkills', skills.data);
  });
};

const getCountries = () => {
  return api.data.countries().then(({ data: countries }) => {
    store.commit('applicant/setCountries', countries.countries);
  });
};

const saveScorecard = async (fieldData) => {
  if (!fieldData) {
    isManualSaving.value = true;
  }
  await until(isSaving).toBe(false);
  isSaving.value = true;
  const fieldQuestions = fieldData ?? answers.value;
  const hasFieldQuestions =
    fieldQuestions && Object.keys(fieldQuestions).length;
  const canEdit = hasEditAccess.value;
  const allValuesUndefined = Object.values(fieldQuestions).every(
    (value) => value === undefined
  );

  if (!hasFieldQuestions || !canEdit || allValuesUndefined) {
    isSaving.value = false;
    if (isManualSaving.value) {
      isManualSaving.value = false;
      await handleClose();
    }
    return;
  }
  try {
    await api.applications.interviews.saveAnswers(
      interviewId.value ?? interview.value.id,
      { questions: fieldQuestions }
    );
    if (fieldData) {
      if (
        questions.value.find(
          ({ id }) => id.toString() === Object.keys(fieldData)[0]
        )?.type === 'rating'
      ) {
        store.commit('applicant/setInterviewsNeedUpdate');
        post({ data: 'interviews', timestamp: Date.now() });
      }
      showAutoSavedMessage.value = true;
      setTimeout(() => {
        showAutoSavedMessage.value = false;
      }, 2000);
    } else {
      showSavedMessage.value = true;
      setTimeout(() => {
        showSavedMessage.value = false;
        handleClose();
      }, 2000);
    }
  } catch (error) {
    console.log(error);
  } finally {
    isSaving.value = false;
    isManualSaving.value = false;
  }
};

const publishInterview = async () => {
  try {
    await saveScorecard();
    if (!forcePublish.value) {
      const valid = await triggerValidate();
      if (!valid) return;
    }
    await api.applications.interviews.publishInterview(
      interviewId.value ?? interview.value.id,
      {
        ...postDecisionActions.value,
      }
    );
    store.commit('applicant/setInterviewsNeedUpdate');
    store.commit('applicant/setDataNeedsUpdate');
    if (route.meta?.requiresInitialData && !isClosed.value) {
      post({ data: 'interviews', timestamp: Date.now() });
      post({ data: 'application', timestamp: Date.now() });
    }
    showSavedMessage.value = true;
    setTimeout(() => {
      showSavedMessage.value = false;
    }, 2000);
    await handleClose();
  } catch (error) {
    console.log(error);
  }
};

// initializers
getInterviewQuestions();
if (route.meta?.requiresInitialData) {
  getApplicantInfo();
  getInterview();
}
if (route.meta?.requiresInitialData || route.name === 'Home') {
  getApplicantTests();
  getTests();
  getSkills();
  getInterviewers();
  getCountries();
}

// provide and inject
const codilityTests = inject('codilityTests', codilityTestsValue);
provide('codilityTests', codilityTests);

// Data transformation and utility functions
const parseAnswer = (string_) => {
  try {
    return JSON.parse(string_);
  } catch {
    return string_ || [];
  }
};

const stringifyAnswer = (answer) =>
  Array.isArray(answer)
    ? answer.join(', ')
    : parseIfStringifiedArray(answer)?.toString?.();

const isBasicQuestion = ({ condition, group }) =>
  !condition && !['Notes', 'Final decision'].includes(group);

const getFollowUps = (question, conditionalQuestions) =>
  conditionalQuestions.filter(
    ({ parent_id: parentId }) => parentId === question.id
  );

const parseIfStringifiedArray = (string_) => {
  try {
    let parsed = JSON.parse(string_);
    return Array.isArray(parsed) ? parsed : string_;
  } catch (error) {
    return string_;
  }
};

const getAnswer = (
  question,
  answers,
  followUpToShow,
  parentAnswer,
  followUpAnswer
) => {
  return {
    label: [
      question.id === 214 ? answers?.[215] && 'Flexible with preferences' : '',
      stringifyAnswer((parentAnswer?.label ?? answers[question.id]) || '-'),
      stringifyAnswer(followUpAnswer?.label ?? answers[followUpToShow?.id]),
    ]
      .filter(Boolean)
      .join(followUpToShow?.type === 'radio' ? ', ' : '. '),
    highlight: followUpAnswer?.highlight ?? parentAnswer?.highlight,
  };
};

const setValues = () => {
  applicant.value = props.applicationData?.id
    ? props.applicationData
    : applicant.value;
  interview.value = props.interview?.id ? props.interview : interview.value;
  averageCodilityScore.value = props.codilityScore
    ? props.codilityScore
    : codilityScoreValue.value;
  if (route.meta?.requiresInitialData) {
    applicant.value = applicantInfo.value;
    averageCodilityScore.value = codilityScoreValue.value;
  }
};

const getRequiredScorecardQuestions = () => {
  return (schema.value?.questions ?? [])
    .filter(({ required, parent_id: parentId, condition }) => {
      if (condition) {
        const parentQuestion = (schema.value?.questions ?? []).find(
          ({ id }) => id === parentId
        );
        const parentAnswer = answers.value[parentId];
        const parentAnswerId = parentQuestion.answers.find(
          ({ label }) => label === parentAnswer
        )?.id;
        return required && parentAnswerId === condition;
      }
      return required;
    })
    .map(({ name, id }) => `${id}_${name.replace(/[^a-zA-Z0-9]+/g, '-')}`);
};

const getRequiredCrossCheckQuestions = () => {
  const commitmentType = parseAnswer(applicant.value?.commitment_type);
  if (interview.value?.name !== 'Screening interview') {
    return { fieldNames: [], properties: [] };
  }
  return {
    properties: [
      'country',
      'city',
      'commitment_type',
      ...(isRateGuidanceEnabled.value
        ? ['gig_overtime_hourly_rate', 'monthly_rate']
        : ['hourly_rate']),
      'currency',
      ...(commitmentType?.includes?.('Full-time') ||
      commitmentType?.includes?.('As needed')
        ? ['first_start_fulltime', 'monthly_rate']
        : []),
      ...(commitmentType?.includes?.('Part-time') ||
      commitmentType?.includes?.('As needed')
        ? ['first_start_parttime']
        : []),
      ...(isFullstack.value
        ? ['frontend_skill', 'backend_skill']
        : ['primary_skill']),
    ],
    fieldNames: [
      'Country',
      'City',
      'Current availability',
      'Hourly rate',
      ...(isRateGuidanceEnabled.value ? ['Full-time monthly rate'] : []),
      'Currency',
      ...(commitmentType?.includes?.('Full-time') ||
      commitmentType?.includes?.('As needed')
        ? ['Full-time notice period', 'Full-time monthly rate']
        : []),
      ...(commitmentType?.includes?.('Part-time') ||
      commitmentType?.includes?.('As needed')
        ? ['Part-time notice period']
        : []),
      ...(isFullstack.value
        ? [
            'Frontend skill',
            'Frontend skill Experience',
            'Frontend skill Level of expertise',
            'Backend skill',
            'Backend skill Experience',
            'Backend skill Level of expertise',
          ]
        : [
            'Primary skill',
            'Primary skill Experience',
            'Primary skill Level of expertise',
          ]),
    ],
  };
};

const getFinalDecisionActionFields = () => {
  if (postDecisionActions.value.action_to_take && !published.value) {
    const requiredActionProperties = {
      reject: [
        'reasons',
        ...(postDecisionActions.value.details_required ? ['details'] : []),
      ],
      ['technical-interview']: ['interviewer_id', 'interview_group_id'],
      ['technical-assessment']: ['codility_test_id'],
    };
    return {
      properties: [
        'action_to_take',
        ...(requiredActionProperties[
          postDecisionActions.value.action_to_take
        ] ?? []),
      ],
      fieldNames: [
        ...(postDecisionActions.value.action_to_take === 'reject'
          ? [
              'Main reason',
              ...(postDecisionActions.value.details_required
                ? ['Rejection details']
                : []),
            ]
          : ['Post Decision Action']),
      ],
    };
  } else {
    return { fieldNames: [], properties: [] };
  }
};

// questions modifier
const questions = computed(() => {
  let lastGroup = null;
  let lastLabel = null;

  const parsedQuestions = (schema.value?.questions ?? [])
    .filter(isBasicQuestion)
    .map((question) => {
      const parentAnswer = question.answers?.find?.(
        ({ label }) => label === answers.value[question.id]
      );
      const followUps = getFollowUps(question, conditionalQuestions.value);
      const followUpToShow = followUps.find(
        ({ condition }) => condition === parentAnswer?.id
      );
      const followUpAnswer = followUpToShow?.answers?.find?.(
        ({ label }) => label === answers.value[followUpToShow?.id]
      );
      const answer = getAnswer(
        question,
        answers.value,
        followUpToShow,
        parentAnswer,
        followUpAnswer
      );

      const firstInGroup = question.group !== lastGroup;
      const firstInLabel = question.label !== lastLabel;
      lastGroup = question.group;
      lastLabel = question.label;

      return { ...question, firstInGroup, firstInLabel, followUps, answer };
    });

  const shouldAddBasicInformationQuestion =
    !isQuestionsLoading.value &&
    interview.value?.name === 'Screening interview';
  const finalDecisionQuestion = {
    firstInGroup: false,
    firstInLabel: false,
    followUps: [],
    answer: {},
    required: true,
    id: 0,
    ...(schema.value?.questions ?? []).find(
      ({ group, type }) => group === 'Final decision' && type !== 'rating'
    ),
  };
  const ratingQuestion = (schema.value?.questions ?? []).find(
    ({ type }) => type === 'rating'
  );
  return [
    ...parsedQuestions,
    ...(shouldAddBasicInformationQuestion
      ? [
          {
            id: 0,
            group: 'Basic information (update, if required)',
            label:
              'This will be updated in the applicant’s profile after submitting.',
            type: 'cross_check',
            firstInGroup: true,
            firstInLabel: true,
            required: true,
            followUps: [],
            answer: {},
          },
        ]
      : []),
    ...(ratingQuestion
      ? [
          {
            firstInGroup: false,
            firstInLabel: false,
            followUps: [],
            answer: getAnswer(ratingQuestion, answers.value),
            required: true,
            id: 0,
            ...ratingQuestion,
          },
        ]
      : []),
    finalDecisionQuestion,
  ];
});

// UI and event handling
const closeRescheduleDialog = () => {
  isRescheduleDialogOpen.value = false;
  rescheduleReason.value = '';
};

const openRescheduleDialog = ({ reason }) => {
  rescheduleReason.value = reason;
  isRescheduleDialogOpen.value = true;
};

const handleClose = async () => {
  await until(isSaving).toBe(false);
  if (route.meta?.requiresInitialData) {
    window.close();
  } else {
    emit('close');
  }
};

// watchers
watch(
  () => published.value,
  (newValue, oldValue) => {
    if (newValue && !oldValue) {
      readOnly.value = true;
    }
  }
);

watchEffect(() => {
  if (!published.value) {
    readOnly.value = !hasEditAccess.value;
  }
  if (isLoading.value) return;

  setValues();

  requiredScorecardQuestions.value = getRequiredScorecardQuestions();

  const requiredCrossCheckQuestions =
    getRequiredCrossCheckQuestions().fieldNames;

  const requiredFinalDecisionActionFields =
    getFinalDecisionActionFields().fieldNames;

  requiredFields.value = !forcePublish.value
    ? [
        ...(requiredScorecardQuestions.value ?? []),
        ...(requiredCrossCheckQuestions ?? []),
        ...(requiredFinalDecisionActionFields ?? []),
      ]
    : requiredFinalDecisionActionFields;
});
</script>

<template>
  <Suspense>
    <div class="bg-white flex flex-col h-full font-inter !overflow-y-hidden">
      <ApplicantActivityInterviewScorecardHeader
        :interview-name="interview?.name"
        :interview-id="interviewId ?? interview?.id"
        :application-id="applicationId ?? String(applicant?.id)"
        :meeting-link="interview?.calendarEvent?.meeting_link"
        :requires-initial-data="route.meta?.requiresInitialData"
        :time-remaining="timeRemaining"
        :is-rate-guidance-enabled="isRateGuidanceEnabled"
        data-testid="scorecard-header"
        @click:close="handleClose"
      />

      <ApplicantActivityInterviewScorecardBody
        ref="scorecardBody"
        v-model:answers="answers"
        v-model:read-only="readOnly"
        v-model:force-publish="forcePublish"
        :applicant="applicant"
        :application-id="applicationId ?? String(applicant?.id)"
        :codility-score="averageCodilityScore"
        :form-progress="formProgress"
        :interview="interview"
        :notes-question="notesQuestion"
        :rating-question="ratingQuestion"
        :published="published"
        :questions="questions"
        :requires-initial-data="route.meta?.requiresInitialData"
        :show-validation-alert="showValidationAlert"
        :time-remaining="timeRemaining"
        :is-loading="isLoading"
        :is-rate-guidance-enabled="isRateGuidanceEnabled"
        @open-reschedule-dialog="
          (val) =>
            route.meta?.requiresInitialData
              ? openRescheduleDialog(val)
              : emit('open-reschedule-dialog', val)
        "
        @save-answers="(val) => saveScorecard(val)"
        @validate-form="triggerValidate"
      />

      <ApplicantActivityInterviewScorecardFooter
        :form-progress="formProgress"
        :read-only="readOnly"
        :is-saving="isManualSaving"
        :published="Boolean(published)"
        :show-auto-saved-message="showAutoSavedMessage"
        :show-saved-message="showSavedMessage"
        :force-publish="forcePublish"
        @click:publish="publishInterview"
        @click:save="saveScorecard"
        @click:close="handleClose"
      />
      <PDialog
        v-if="route.meta?.requiresInitialData"
        v-model="isRescheduleDialogOpen"
        persistent
        position="standard"
      >
        <RescheduleCard
          :interview-id="interview.id"
          :interview-name="interview.name"
          :interviewers="interviewers"
          :initial-interviewer="interview.interviewer"
          :recruiters="recruiters"
          :close-reschedule-dialog="closeRescheduleDialog"
          :on-reschedule="getInterview"
          :is-no-show-flow="rescheduleReason === 'no-show'"
        />
      </PDialog>
    </div>
  </Suspense>
</template>
