<script>
/**
 * @typedef { import('vue').Ref } Ref
 */

/**
 * Represents the data to be confirmed.
 * @typedef {Object} DataToConfirm
 * @property {string} country - The country value.
 * @property {number} monthlyRate - The monthly rate value.
 * @property {number} hourlyRate - The hourly rate value.
 */
export default {
  name: 'ApplicationStageChangeConfirmationPopup',
};
</script>
<script setup>
import { useStore } from 'vuex';
import api from '@/api';
import { computed, onMounted, ref, toRefs, watch } from 'vue';
import { interviewUtils } from '@/utils';
import {
  BaseBadge,
  BaseConfirmationCard,
  BaseInputDropdown,
  BaseInputField,
} from '@/components/Base';
import { useForm } from 'vee-validate';
const karatInterviewer = {
  name: 'Karat',
  value: 'karat',
};

const props = defineProps({
  onCancel: {
    type: Function,
    required: true,
  },
  isLoading: {
    type: Boolean,
    required: true,
  },
  isInterviewerRequired: {
    type: Boolean,
    required: true,
  },
  isRecruiterRequired: {
    type: Boolean,
    required: true,
  },
  initialRecruiter: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  initialInterviewer: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  applicationId: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  onUpdateRecruiter: {
    type: Function,
    required: true,
  },
  newStage: {
    type: Object,
    required: true,
  },
  currentStage: {
    type: Object,
    required: true,
  },
  applicantName: {
    type: String,
    required: true,
  },
  country: {
    type: String,
    required: false,
    default() {
      return '';
    },
  },
  monthlyRate: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  gigOvertimeHourlyRate: {
    type: Number,
    required: false,
    default() {
      return null;
    },
  },
  isRateGuidanceEnabled: {
    type: Boolean,
    required: true,
  },
});

const {
  isRecruiterRequired,
  isInterviewerRequired,
  initialRecruiter,
  initialInterviewer,
  newStage,
  applicationId,
  onUpdateRecruiter,
  country,
  monthlyRate,
  gigOvertimeHourlyRate,
  isRateGuidanceEnabled,
} = toRefs(props);

const emit = defineEmits(['change-stage']);

const store = useStore();

const confirmationPopup = ref(null);
const currentRecruiter = ref(null);
const currentInterviewer = ref(null);
const isInvalidRecruiter = ref(undefined);
const isInvalidInterviewer = ref(undefined);
const isKaratEnabled = ref(false);
const isKaratConfirmationDialogOpen = ref(false);
const isKaratVolumeExhausted = ref(false);

/** @type {Ref<DataToConfirm>} */
const dataToConfirm = ref({
  country: country.value,
  monthlyRate: monthlyRate.value,
  hourlyRate: isRateGuidanceEnabled.value ? gigOvertimeHourlyRate.value : null,
});

const recruiters = computed(() => {
  return store.state.applicant.recruiters;
});
const interviewers = computed(() => {
  return store.state.application.interviewers;
});
const countries = computed(() => store.state.applicant.countries);

const interviewersList = computed(() => {
  return interviewUtils.generateGroupList({
    groupsData: interviewers.value?.groups,
    itemsData: interviewers.value?.interviewers,
    isKaratEnabled: isKaratEnabled.value,
  });
});

const recruitersList = computed(() => {
  if (isScreeningStage.value) {
    return interviewUtils.generateGroupList({
      groupsData: recruiters.value?.groups,
      itemsData: recruiters.value?.recruiters,
      groupNamePrefix: '',
    });
  }
  return recruiters.value?.recruiters;
});

const needAdditionalInformation = computed(() => {
  return (
    (recruiters.value &&
      isRecruiterRequired.value &&
      !initialRecruiter.value) ||
    (interviewers.value &&
      isInterviewerRequired.value &&
      !initialInterviewer.value)
  );
});

const isScreeningStage = computed(() => {
  return (
    newStage.value?.interview_required && !newStage.value?.interviewer_required
  );
});

/**
 * Checks whether the current stage is the "Joined Network" stage.
 *
 * @type {Ref<boolean>}
 */
const isJoinedNetworkStage = computed(() => {
  return newStage.value?.id === 7;
});

/**
 * Determines whether to show the interviewer dropdown or not.
 *
 * @type {Ref<boolean>}
 */
const showInterviewerDropdown = computed(
  () =>
    interviewersList.value.length > 1 &&
    isInterviewerRequired.value &&
    (!initialInterviewer.value || isInvalidInterviewer.value)
);

/**
 * Computed property to determine whether to show the recruiter dropdown.
 *
 * @type {Ref<boolean>}
 */
const showRecruiterDropdown = computed(
  () =>
    recruitersList.value.length > 1 &&
    isRecruiterRequired.value &&
    (!initialRecruiter.value ||
      isInvalidRecruiter.value ||
      isScreeningStage.value)
);

const areRequiredRolesSelected = computed(() => {
  return !(
    (isInterviewerRequired.value && !currentInterviewer.value?.name) ||
    (isRecruiterRequired.value && !currentRecruiter.value?.id)
  );
});

const schema = computed(() => {
  return {
    Interviewers: {
      required: showInterviewerDropdown.value,
    },
    Recruiters: {
      required: showRecruiterDropdown.value,
    },
    Country: {
      required: isJoinedNetworkStage.value,
    },
    'Hourly rate': {
      required: isJoinedNetworkStage.value,
      min_value: 1,
    },
    'Monthly rate': {
      required: isJoinedNetworkStage.value,
      min_value: 1,
    },
  };
});

const { handleSubmit } = useForm({
  validationSchema: schema,
});

onMounted(() => {
  dataToConfirm.value = {
    country:
      countries.value.find(({ code, name }) =>
        [code, name].includes(country.value)
      ) ?? null,
    monthlyRate: monthlyRate.value,
    hourlyRate: isRateGuidanceEnabled.value
      ? gigOvertimeHourlyRate.value
      : null,
  };
  watch(
    () => initialRecruiter.value,
    (value) => {
      if (isScreeningStage.value) {
        currentRecruiter.value =
          recruitersList.value.find(
            ({ id, type }) => type !== 'group' && id === value
          ) ?? recruitersList.value[0];
      } else {
        currentRecruiter.value = recruitersList.value.find(
          ({ id, type }) => type !== 'group' && id === value
        );
      }

      if (value) {
        isInvalidRecruiter.value = !recruitersList.value.some(
          ({ id, active, type }) => type !== 'group' && id === value && active
        );
      }
    },
    { immediate: true }
  );

  watch(
    () => initialInterviewer.value,
    (value) => {
      currentInterviewer.value = (interviewers.value?.interviewers ?? []).find(
        ({ id }) => id === value
      );
      if (value) {
        isInvalidInterviewer.value = !(
          interviewers.value?.interviewers ?? []
        ).some(({ id, active }) => id === value && active);
      }
    },
    { immediate: true }
  );

  if (newStage.value?.id === 5) {
    checkIsKaratEnabled();
  }
});

const setDefaultInterviewer = ({
  isKaratEligible,
  defaultInterviewerGroup,
}) => {
  currentInterviewer.value = isKaratEligible
    ? karatInterviewer
    : interviewersList.value.find(
        (interviewer) =>
          interviewer.type === 'group' &&
          interviewer.id === defaultInterviewerGroup.id
      );
};

const handleConfirm = async () => {
  if (!areRequiredRolesSelected.value) {
    return;
  }
  try {
    await updateAssigneesIfChanged();
    await inviteKaratIfEnabled();
    await confirmRates();
    confirmSelection();
  } catch (error) {
    console.error(error);
    props.onCancel();
  }
};

const handleInvalidSubmit = ({ errors }) => {
  const invalidQuestions = Object.keys(errors);
  const firstInvalidQuestionElement = document.querySelector(
    `[data-name='${invalidQuestions[0]}']`
  );
  if (firstInvalidQuestionElement) {
    firstInvalidQuestionElement.scrollIntoView();
  }
};

const onSubmit = handleSubmit(handleConfirm, handleInvalidSubmit);

const updateAssigneesIfChanged = async () => {
  if (
    !initialRecruiter.value ||
    (currentRecruiter.value &&
      currentRecruiter.value.id !== initialRecruiter.value)
  ) {
    await api.applications.updateAssignees(applicationId.value, {
      recruiter_id:
        currentRecruiter.value?.type !== 'group'
          ? currentRecruiter.value?.id
          : null,
    });
    onUpdateRecruiter.value({
      applicationId: applicationId.value,
      recruiterId: currentRecruiter.value?.id,
    });
  }
};

const confirmRates = async () => {
  if (!isJoinedNetworkStage.value) {
    return;
  }
  await api.applications.confirmRates(applicationId.value, {
    rate: {
      monthly_rate_confirmed: dataToConfirm.value.monthlyRate,
      ...(isRateGuidanceEnabled.value
        ? {
            gig_overtime_hourly_rate_confirmed: dataToConfirm.value.hourlyRate,
          }
        : {
            hourly_rate_confirmed: dataToConfirm.value.hourlyRate,
          }),
    },
    confirmed_country: dataToConfirm.value.country?.code,
  });
};

const inviteKaratIfEnabled = async () => {
  if (isKaratEnabled.value && currentInterviewer.value?.value === 'karat') {
    await api.applications.karat.inviteApplicant(applicationId.value);
    store.commit('ui/addSnackbarMessage', {
      title: 'Applicant invited to Karat interview',
      body: "You can follow the interview under the interviewers tab on a candidate's page",
      type: 'success',
      displayDuration: 3000,
    });
  }
};

const confirmSelection = () => {
  emit('change-stage', {
    interviewerId:
      currentInterviewer.value?.type !== 'group'
        ? currentInterviewer.value?.id
        : undefined,
    interviewerGroup:
      currentInterviewer.value?.type === 'group'
        ? currentInterviewer.value?.id
        : undefined,
    recruiterId:
      currentRecruiter.value?.type !== 'group'
        ? currentRecruiter.value?.id
        : undefined,
    recruiterGroup:
      currentRecruiter.value?.type === 'group'
        ? currentRecruiter.value?.id
        : undefined,
    skipInterview: currentInterviewer.value?.value === 'karat',
  });
};

const checkIsKaratEnabled = async () => {
  const { data: response } = await api.applications.karat.isKaratEnabled(
    applicationId.value
  );
  isKaratEnabled.value = response.is_handled;
  isKaratVolumeExhausted.value = response.is_volume_exhausted;
  setDefaultInterviewer({
    isKaratEligible: isKaratEnabled.value && !isKaratVolumeExhausted.value,
    defaultInterviewerGroup: response.default_interview_group,
  });
};
</script>
<template>
  <div>
    <BaseConfirmationCard
      v-show="!isKaratConfirmationDialogOpen"
      ref="confirmationPopup"
      cancel-button-text="Cancel"
      :confirm-button-text="
        currentInterviewer?.value === 'karat' ? 'Continue' : 'Confirm'
      "
      :disabled="
        (isInterviewerRequired && !currentInterviewer?.name) ||
        (isRecruiterRequired && !currentRecruiter?.id)
      "
      :is-loading="isLoading"
      :on-close="onCancel"
      :on-confirm="
        currentInterviewer?.value === 'karat'
          ? () => (isKaratConfirmationDialogOpen = true)
          : onSubmit
      "
      paddingless
    >
      <template #title>
        <div class="flex gap-2">
          <span>
            {{
              needAdditionalInformation
                ? 'More info required to change the stage'
                : 'Change stage'
            }}
          </span>
          <BaseBadge
            v-if="isRateGuidanceEnabled && isJoinedNetworkStage"
            color="warning"
            label="Monthly Comp Member"
          ></BaseBadge>
        </div>
      </template>
      <div
        v-if="!needAdditionalInformation"
        class="flex flex-col gap-1.5 px-6"
      >
        <div class="text-proxify-gray-600 text-body-sm font-medium">
          You are about to change
          <span class="font-semibold text-proxify-gray-900">
            {{ applicantName }}'s
          </span>
          stage:
        </div>
        <div
          v-if="currentStage && newStage"
          class="font-medium text-body-md text-proxify-gray-700"
        >
          {{ currentStage.name }} → {{ newStage?.name }}
        </div>
      </div>
      <template v-else>
        <div class="text-proxify-gray-600 text-body-sm font-medium px-6">
          Candidate's action history will be updated.
        </div>
      </template>
      <div class="pt-4 px-6 flex flex-col gap-4">
        <div
          v-if="showInterviewerDropdown"
          class="flex flex-col gap-4"
        >
          <div
            class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
          >
            <span v-if="isInvalidInterviewer">Reassign</span>
            Interviewer
            <BaseInputDropdown
              v-model="currentInterviewer"
              name="Interviewers"
              :options="interviewersList"
              option-label="prefix"
              option-sublabel="name"
              map-options
              type="search"
            ></BaseInputDropdown>
          </div>
        </div>
        <div
          v-if="showRecruiterDropdown"
          class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
        >
          <span v-if="isInvalidInterviewer">Reassign</span>
          Recruiter
          <BaseInputDropdown
            v-model="currentRecruiter"
            name="Recruiters"
            :options="recruitersList"
            option-label="name"
            map-options
            type="search"
            :prepend-icon="
              currentRecruiter?.name === 'General Screening Group' &&
              !initialRecruiter
                ? 'star06'
                : 'search-lg'
            "
            :prepend-icon-class="{
              'text-proxify-purple-600':
                currentRecruiter?.name === 'General Screening Group' &&
                !initialRecruiter,
            }"
          ></BaseInputDropdown>
          <div
            v-if="currentRecruiter?.name === 'General Screening Group'"
            class="text-proxify-purple-600 font-normal"
            data-testid="recruiter-dropdown-warning-message"
          >
            When the applicant schedules the Screening meeting, the recruiter
            will be automatically assigned.
          </div>
        </div>
        <template v-if="isJoinedNetworkStage">
          <div class="text-sm text-proxify-gray-600">
            Please tell us the exact rate & tax country confirmed by PandaDoc
            agreement.
          </div>
          <div
            class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
          >
            Country of residence (for tax purposes)
            <BaseInputDropdown
              v-model="dataToConfirm.country"
              name="Country"
              :options="countries"
              option-label="name"
              map-options
              type="search"
              data-testid="residence-country-dropdown"
            ></BaseInputDropdown>
          </div>
          <div
            class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
          >
            Signed monthly rate
            <BaseInputField
              v-model="dataToConfirm.monthlyRate"
              name="Monthly rate"
              :leading-text="
                dataToConfirm.country?.currency === 'USD' ? '$' : '€'
              "
              :trailing-text="
                dataToConfirm.country?.currency === 'USD' ? 'USD' : 'EUR'
              "
              type="number"
              min="0"
              placeholder="0,00"
              :trailing-border="false"
              validate-on-value-update
              data-testid="monthly-rate-input"
            />
          </div>
          <div
            class="font-medium text-body-sm font-proxify-gray-700 flex flex-col gap-1.5"
          >
            <span v-if="isRateGuidanceEnabled">
              Signed gig/overtime hourly rate
            </span>
            <span v-else>Signed hourly rate</span>
            <BaseInputField
              v-model="dataToConfirm.hourlyRate"
              name="Hourly rate"
              :leading-text="
                dataToConfirm.country?.currency === 'USD' ? '$' : '€'
              "
              type="number"
              min="0"
              placeholder="0,00"
              :trailing-border="false"
              :trailing-text="
                dataToConfirm.country?.currency === 'USD' ? 'USD' : 'EUR'
              "
              validate-on-value-update
              data-testid="hourly-rate-input"
            />
          </div>
        </template>
      </div>
    </BaseConfirmationCard>

    <BaseConfirmationCard
      v-show="isKaratConfirmationDialogOpen && newStage?.id === 5"
      cancel-button-text="Go Back"
      confirm-button-text="Confirm"
      cancel-button-state="primary"
      :on-close="() => (isKaratConfirmationDialogOpen = false)"
      :on-confirm="onSubmit"
      data-testid="karat-confirmation-dialog"
    >
      <template #title>Invite to Karat interview</template>
      <div class="text-sm">
        The applicant will get a Technical Interview E-mail from Karat
        automatically.
        <div class="mt-4 mb-10">Do you confirm?</div>
      </div>
    </BaseConfirmationCard>
  </div>
</template>
